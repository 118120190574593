import { useState } from 'react';
import I18n from '../i18n';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithPopup,
} from 'firebase/auth';
import { level1ApiEndpoint } from '../utils/apiEndpointUrl';
import { loggerError } from '../utils/logger';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { FirebaseError } from 'firebase/app';

export default function SignUp() {
  const [httpResponseResult, setHttpResponseResult] = useState({
    success: '',
    error: '',
  });
  const auth = getAuth();
  auth.languageCode = 'ja';

  const continueWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) {
          return;
        }
        const token = credential.accessToken;
        if (!token) {
          return;
        }
        const formData = new FormData();
        const user = result.user;
        if (!user.email) {
          return;
        }
        formData.append('email', user.email);
        user
          .getIdToken(true)
          .then((token) => {
            fetch(`${level1ApiEndpoint()}/create-session`, {
              headers: { Authorization: token },
              method: 'POST',
              body: formData,
              credentials: 'include',
            })
              .then((response) => {
                if (response.ok) {
                  const message = structuredClone(httpResponseResult);
                  message.success = I18n.t(
                    'individual_pages.sign_up.successfully_created_user'
                  );
                  setHttpResponseResult(message);
                  window.location.replace('/welcome');
                } else {
                  throw new Error(
                    '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                  );
                }
              })
              .catch(() => {
                throw new Error(
                  '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                );
              });
          })
          .catch((e: Error) => {
            const message = structuredClone(httpResponseResult);
            message.error = e.message;
            setHttpResponseResult(message);
          });
      })
      .catch((err) => {
        const message = structuredClone(httpResponseResult);
        message.error = err.message;
        setHttpResponseResult(message);
        loggerError('failed to signin', err);
      });
  };

  const signUpWithEmail = async (email: string, password: string) => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredencial) => {
        const formData = new FormData();
        const user = userCredencial.user;
        if (!user) {
          return;
        }
        if (!user.email) {
          throw new Error('Failed to get user email');
        }
        formData.append('email', user.email);
        user
          .getIdToken(true)
          .then((token) => {
            fetch(`${level1ApiEndpoint()}/create-session`, {
              headers: { Authorization: token },
              method: 'POST',
              body: formData,
              credentials: 'include',
            })
              .then(async (response) => {
                if (response.ok) {
                  const message = structuredClone(httpResponseResult);
                  message.success = I18n.t(
                    'individual_pages.sign_up.successfully_created_user'
                  );
                  setHttpResponseResult(message);
                  await sendEmailVerification(user);
                  window.location.replace('/welcome');
                } else {
                  throw new Error(
                    '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                  );
                }
              })
              .catch(() => {
                throw new Error(
                  '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                );
              });
          })
          .catch((e: Error) => {
            const message = structuredClone(httpResponseResult);
            message.error = e.message;
            setFormError(message.error);
            setHttpResponseResult(message);
          });
      })
      .catch((err: FirebaseError) => {
        if (err.code === 'auth/email-already-in-use') {
          setFormError('このメールアドレスはすでに登録されています。');
        } else {
          setFormError('エラーが発生しました。');
        }
      });
  };

  type signUpForm = {
    email: string;
    password: string;
    passwordConfirmation: string;
  };

  const [form, setForm] = useState<signUpForm>({
    email: '',
    password: '',
    passwordConfirmation: '',
  });

  const [formError, setFormError] = useState('');

  const onSubmitSignUpForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.password !== form.passwordConfirmation) {
      setFormError('パスワードとパスワード確認が一致しません。');
      return;
    }
    await signUpWithEmail(form.email, form.password);
  };

  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 p-4">
      <div className="p-4 max-w-[60%] m-auto ">
        <img
          alt="Your Company"
          src="/images/signup-cat.png"
          className="mx-auto max-w-full rounded"
        />
      </div>
      <div className="p-4">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="SIGQ Cloud Linker"
              src="/images/logo.png"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
              新規登録
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full shadow shadow-gray-300 p-5 rounded">
            <div className="rounded-md bg-red-50 p-4 my-5" hidden={!formError}>
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-red-400"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    入力された内容が正しくありません。
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      <li>{formError}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <form className="space-y-6" onSubmit={onSubmitSignUpForm}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  メールアドレス
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        email: e.target.value,
                      });
                      setFormError('');
                    }}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    パスワード
                  </label>
                </div>
                <div className="mt-2">
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      minLength={8}
                      required
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
                      autoComplete="current-password"
                      placeholder="最低8文字以上、大文字・小文字・数字を全て含む"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          password: e.target.value,
                        });
                        setFormError('');
                      }}
                      className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm"
                    />
                    {!showPassword && (
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
                        onClick={() => {
                          setShowPassword(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </button>
                    )}
                    {showPassword && (
                      <button
                        type="button"
                        id="togglePassword"
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
                        onClick={() => {
                          setShowPassword(false);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    パスワード確認
                  </label>
                </div>
                <div className="mt-2">
                  <div className="relative">
                    <input
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type={showPassword ? 'text' : 'password'}
                      required
                      onChange={(e) => {
                        setForm({
                          ...form,
                          passwordConfirmation: e.target.value,
                        });
                        setFormError('');
                      }}
                      autoComplete="current-password"
                      className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm"
                    />
                    {!showPassword && (
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
                        onClick={() => {
                          setShowPassword(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </button>
                    )}
                    {showPassword && (
                      <button
                        type="button"
                        id="togglePassword"
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
                        onClick={() => {
                          setShowPassword(false);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  新規登録
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm/6 text-gray-500">
              <a
                href="/signin"
                className="font-semibold text-blue-600 hover:text-blue-500"
              >
                すでにアカウントをお持ちの方はこちら
              </a>
            </p>
            <hr className="my-5" />
            <div>
              <p className="m-auto">
                <button className="w-48 my-5" onClick={continueWithGoogle}>
                  <img src="/images/web_light_rd_ctn@4x.png" loading="lazy" />
                </button>
              </p>

              <p className="text-xs text-gray-400">
                「Continue with Google」をクリックすることで、
                <a href="/term" className="hover:underline" target="_blank">
                  {I18n.t('noun.terms')}
                </a>
                、
                <a
                  href="/privacy-policy"
                  className="hover:underline"
                  target="_blank"
                >
                  {I18n.t('noun.privacy_policy')}
                </a>
                と
                <a
                  href="https://support.sigq.jp/hc/ja/articles/39357914025753--%E3%83%99%E3%83%BC%E3%82%BF%E7%89%88%E5%88%A9%E7%94%A8%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E9%87%8D%E8%A6%81%E3%81%AA%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B"
                  className="hover:underline"
                  target="blank"
                >
                  ベータ版注意・免責事項
                </a>
                に同意したものとみなします。また、必要に応じて、メールで広告、プロモーション、その他の通知を送信することがあります。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
