import { FirebaseError } from 'firebase/app';
import {
  EmailAuthProvider,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { level1ApiEndpoint } from '../utils/apiEndpointUrl';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { loggerError } from '../utils/logger';

export default function MfaForm({ type }: { type: 'signin' | 'disableMfa' }) {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [smsCode, setSmsCode] = useState('');
  const [shouldOpenMfa, setShouldOpenMfa] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resolver, setResolver] = useState<MultiFactorResolver>();
  const [formError, setFormError] = useState('');
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [disableSubmitCode, setDisableSubmitCode] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const [isShowElement, setIsShowElement] = useState(false);
  const [status, setStatus] = useState('');
  const handleVerifyCode = async () => {
    setFormError('');
    setDisableSubmitCode(true);
    if (!verificationId) {
      setDisableSubmitCode(false);
      setStatus('No verification ID found. Please restart the process.');
      return;
    }
    try {
      const phoneAuthCredential = PhoneAuthProvider.credential(
        verificationId,
        smsCode
      );
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
      if (resolver) {
        await resolver.resolveSignIn(multiFactorAssertion);
        const auth = getAuth();
        const user = auth.currentUser;

        if (user && user.email) {
          if (type === 'disableMfa') {
            const multiFactorUser = multiFactor(user);
            const enrolledFactors = multiFactorUser.enrolledFactors;
            await multiFactor(user)
              .unenroll(enrolledFactors[0])
              .then(() => {
                setStatus('2段階認証が解除されました。');
              })
              .catch((err) => {
                setFormError('2段階認証の解除に失敗しました');
                loggerError('2段階認証の解除に失敗しました: ', err);
              });
          }

          const formData = new FormData();
          formData.append('email', user.email);
          user
            .getIdToken(true)
            .then((token) => {
              fetch(`${level1ApiEndpoint()}/create-session`, {
                headers: { Authorization: token },
                method: 'POST',
                body: formData,
                credentials: 'include',
              })
                .then(async (response) => {
                  if (response.ok) {
                    if (type === 'signin') {
                      window.location.replace('/welcome');
                    } else {
                      window.location.reload();
                    }
                  } else {
                    throw new Error(
                      '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                    );
                  }
                })
                .catch((e) => {
                  return e;
                });
            })
            .catch((e: Error) => {
              loggerError('failed to create session', e);
              setFormError(e.message);
            });
        }
      } else {
        loggerError(
          'Error: No resolver found.',
          new Error('Error: No resolver found.')
        );
        setStatus('エラーが発生しました。');
      }
    } catch (err: any) {
      if (err.code === 'auth/invalid-verification-code') {
        setStatus('認証コードが違います。');
      } else {
        loggerError('failed to handle verify code', err);
        setStatus('エラーが発生しました。');
      }
    } finally {
      setDisableSubmitCode(false);
    }
  };
  useEffect(() => {
    if (isShowElement) {
      const initializeRecaptcha = async () => {
        try {
          if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
              auth,
              'recaptcha-container',
              {
                size: 'invisible',
                callback: () => {
                  console.log('reCAPTCHA solved');
                },
              }
            );
          }
          await window.recaptchaVerifier.render();
          setRecaptchaReady(true);
        } catch (error: any) {
          loggerError('Failed to initialize reCAPTCHA:', error);
          setRecaptchaReady(false);
        }
      };

      initializeRecaptcha();
    }

    return () => {
      if (window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier.clear();
        } catch (error: any) {
          loggerError('Failed to clear reCAPTCHA:', error);
        }
        delete window.recaptchaVerifier;
      }
      setRecaptchaReady(false);
    };
  }, [isShowElement, auth]);
  const handleSendSMS = async () => {
    setFormError('');
    setStatus('');
    setIsShowElement(true);

    try {
      if (!window.recaptchaVerifier) {
        throw new Error('Recaptcha not initialized');
      }
      if (!resolver) {
        throw new Error('can not find resolver');
      }
      const provider = new PhoneAuthProvider(auth);
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };
      const verificationId = await provider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier
      );
      setVerificationId(verificationId);
    } catch (error: any) {
      setVerificationId('');
      if (error.code === 'auth/invalid-phone-number') {
        setStatus('電話番号が違います。再度電話番号の入力を行ってください。');
      } else {
        loggerError('failed to handle sms', error);
        setStatus('エラーが発生しました。');
      }
      loggerError('failed to send SMS', error);
    } finally {
      setIsShowElement(false);
    }
  };

  const sendPasswordReset = async () => {
    if (!form.email) {
      setStatus('メールアドレスを入力してください。');
    }
    await sendPasswordResetEmail(auth, form.email)
      .then((resp) => {
        setStatus('パスワード再設定リンクをメールで送信しました。');
      })
      .catch((error) => {
        setStatus('パスワード再設定リンクの送信に失敗しました。');
        loggerError('failed to send password reset', error);
      });
  };

  const onSubmitSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError('');

    await signInWithEmailAndPassword(auth, form.email, form.password)
      .then((userCredencial) => {
        const formData = new FormData();
        const user = userCredencial.user;
        if (!user) {
          return;
        }
        if (!user.email) {
          throw new Error('Failed to get user email');
        }
        formData.append('email', user.email);
        user
          .getIdToken(true)
          .then((token) => {
            fetch(`${level1ApiEndpoint()}/create-session`, {
              headers: { Authorization: token },
              method: 'POST',
              body: formData,
              credentials: 'include',
            })
              .then(async (response) => {
                if (response.ok) {
                  window.location.replace('/welcome');
                } else {
                  throw new Error(
                    '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                  );
                }
              })
              .catch(() => {
                throw new Error(
                  '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                );
              });
          })
          .catch((e: Error) => {
            loggerError('failed to create-session', e);
            setFormError(e.message);
          });
      })
      .catch((err: FirebaseError) => {
        if (err.code === 'auth/user-not-found') {
          setFormError(
            'ユーザーが存在しないか、もしくはパスワードが間違っています。'
          );
        } else if (err.code === 'auth/wrong-password') {
          setFormError(
            'ユーザーが存在しないか、もしくはパスワードが間違っています。'
          );
        } else if (err.code === 'auth/multi-factor-auth-required') {
          setShouldOpenMfa(true);
          const _resolver = getMultiFactorResolver(
            auth,
            err as MultiFactorError
          );
          setIsShowElement(true);
          setResolver(_resolver);
        } else {
          loggerError('failed to signInWithEmailAndPassword', err);
          setFormError('エラーが発生しました');
        }
      });
  };
  const onSubmitDisableMfa = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const credential = EmailAuthProvider.credential(
      currentUser?.email || '',
      form.password
    );

    if (currentUser) {
      await reauthenticateWithCredential(currentUser, credential)
        .then(async (credential) => {
          // do nothing
        })
        .catch((err: FirebaseError) => {
          if (err.code === 'auth/multi-factor-auth-required') {
            setShouldOpenMfa(true);
            const _resolver = getMultiFactorResolver(
              auth,
              err as MultiFactorError
            );
            setResolver(_resolver);
            setIsShowElement(true);
          } else if (err.code === 'auth/wrong-password') {
            setFormError(
              'ユーザーが存在しないか、もしくはパスワードが間違っています。'
            );
          } else {
            loggerError('failed to reauthenticateWithCredential', err);
            setFormError('エラーが発生しました。');
          }
        });
    }
  };

  const onSubmitSetMfa = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const credential = EmailAuthProvider.credential(
      currentUser?.email || '',
      form.password
    );

    if (currentUser) {
      await reauthenticateWithCredential(currentUser, credential)
        .then(async (credential) => {
          setShouldOpenMfa(true);
          setIsShowElement(true);
        })
        .catch((err: FirebaseError) => {
          if (err.code === 'auth/multi-factor-auth-required') {
          } else if (err.code === 'auth/wrong-password') {
            setFormError(
              'ユーザーが存在しないか、もしくはパスワードが間違っています。'
            );
          } else {
            loggerError('failed to reauthenticateWithCredential', err);
            setFormError('エラーが発生しました。');
          }
        });
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setStatus('');
    if (type === 'signin') {
      await onSubmitSignIn(e);
    } else if (type === 'disableMfa') {
      await onSubmitDisableMfa(e);
    } else if (type === 'setMfa') {
      await onSubmitSetMfa(e);
    }
  };

  return (
    <div className="bg-white max-w-md my-5">
      <div className="rounded-md bg-red-50 p-4 my-5" hidden={!formError}>
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              入力された内容が正しくありません。
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                <li>{formError}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <form
        className="space-y-6 rounded shadow-gray-300 shadow p-5"
        onSubmit={onSubmit}
        hidden={shouldOpenMfa}
      >
        <div>
          <label
            htmlFor="email"
            className="block text-sm/6 font-medium text-gray-900"
          >
            メールアドレス
          </label>
          <div className="mt-2">
            <input
              id="mfaEmail"
              name="email"
              type="email"
              required
              onChange={(e) => {
                setStatus('');
                setForm({
                  ...form,
                  email: e.target.value,
                });
              }}
              disabled={type === 'disableMfa'}
              autoComplete="email"
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
            />
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <label
              htmlFor="password"
              className="block text-sm/6 font-medium text-gray-900"
            >
              パスワード
            </label>
            <div className="text-sm">
              <button
                className="font-semibold text-blue-600 hover:text-blue-500"
                onClick={async (e) => {
                  e.preventDefault();
                  await sendPasswordReset();
                }}
              >
                パスワード再設定リンクを送信する
              </button>
            </div>
          </div>
          <div className="mt-2">
            <div className="relative">
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                required
                onChange={(e) => {
                  setStatus('');
                  setForm({
                    ...form,
                    password: e.target.value,
                  });
                }}
                autoComplete="current-password"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm"
              />
              {!showPassword && (
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
                  onClick={() => {
                    setShowPassword(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </button>
              )}
              {showPassword && (
                <button
                  type="button"
                  id="togglePassword"
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
                  onClick={() => {
                    setShowPassword(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {type === 'signin' ? 'ログイン' : '再認証'}
          </button>
        </div>
      </form>
      <div className="mt-6 p-5" hidden={!shouldOpenMfa}>
        <div hidden={!!verificationId}>
          携帯電話番号宛にSMSを送信します。
          <button
            onClick={handleSendSMS}
            className={`mt-4 w-full py-2 px-4 rounded-lg ${
              recaptchaReady
                ? 'bg-blue-500 hover:bg-blue-600 text-white'
                : 'bg-gray-400 text-gray-700 cursor-not-allowed'
            }`}
            disabled={!recaptchaReady}
          >
            認証コードを送信
          </button>
        </div>

        {verificationId && (
          <div className="mt-6">
            <label htmlFor="smsCode" className="block text-gray-700 mb-2">
              SMSに届いた認証コードを入力してください。
            </label>
            <input
              type="text"
              id="smsCode"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={smsCode}
              onChange={(e) => {
                setStatus('');
                setSmsCode(e.target.value);
              }}
            />
            <button
              onClick={handleVerifyCode}
              className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition"
              disabled={disableSubmitCode}
            >
              コードを認証する
            </button>
          </div>
        )}

        {isShowElement && <div id="recaptcha-container"></div>}
        <p>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="mt-5"
          >
            はじめからやり直す
          </button>
        </p>
      </div>
      <p className="mt-4 text-center text-gray-600">{status}</p>
    </div>
  );
}
